import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import M26DImage from "../images/m-series/timberline-m-series-26-disk-16g-16g-tanks.jpg"
import M28DImage from "../images/m-series/timberline-m-series-26-disk-16g-16g-tanks.jpg"
import M26CImage from "../images/m-series/timberline-m-series-26-cylindrical-16g-16g-tanks.jpg"
import M24OImage from "../images/m-series/timberline-m-series-24-orbital-16g-16g-tanks.jpg"
import M28OImage from "../images/m-series/timberline-m-series-24-orbital-16g-16g-tanks.jpg"
import M20DImage from "../images/m-series/timberline-m-series-20-disk-16g-16g-tanks.jpg"
import IndexSection3 from "../components/IndexSection3"

import SEO from "../components/seo"
import Helmet from "react-helmet"

const MpageStyles = styled.div`
  #product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const MseriesPage = () => {
  return (
    <>
      <SEO title="Medium Walk Behind Floor Scrubbers | Timberline Cleaning Equipment" />
      <Helmet>
        <title>
          Medium Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title>
        <description>
          Built to scrub and built to last, the S-Series is the right size for
          many applications.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Medium Walk Behind Floor Scrubbers"
          subHeading="M-Series"
          description="M-Series Walk-Behind Scrubbers are designed to make the operator more productive with multiple scrub head sizes on a versatile sized frame."
          path="20 - 28in (51 - 77cm)"
          tank="Sol: 16gal (60l) Rec: 16gal (60l)"
          time="Up to 3 hours"
          title1="Cleaning Path:"
          title2="Tank Capacity:"
          title3="Run Time:"
        />
        <MpageStyles>
          <div id="product-container">
            <ProductCard
              image={M26DImage}
              title="M26 Disk"
              description="Cleaning Path - 26 in / 660 mm"
              link="/products/m26-disk-scrubber"
            />
            {/* <ProductCard
              image={M24OImage}
              title="M20 Orbital"
              description="Cleaning Path - 20 in / 610 mm"
              link="/products/m20-orbital-scrubber"
            /> */}
            <ProductCard
              image={M26CImage}
              title="M26 Cylindrical "
              description="Cleaning Path - 26 in / 508 mm "
              link="/products/m26-cylindrical-scrubber"
            />
            <ProductCard
              image={M20DImage}
              title="M20 Disk"
              description="Cleaning Path - 20 in / 508 mm"
              link="/products/m20-disk-scrubber"
            />
            <ProductCard
              image={M28DImage}
              title="M28 Disk"
              description="Cleaning Path - 28 in / 710 mm"
              link="/products/m28-disk-scrubber"
            />

            <ProductCard
              image={M24OImage}
              title="M24 Orbital"
              description="Cleaning Path - 24 in / 609 mm"
              link="/products/m24-orbital-scrubber"
            />

            <ProductCard
              image={M28OImage}
              title="M28 Orbital"
              description="Cleaning Path - 28 in / 711 mm"
              link="/products/m28-orbital-scrubber"
            />
          </div>
        </MpageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default MseriesPage
